<template>
  <div class="ps-5 pe-5"> <!-- container -->
    <div class="row">
      <div class="col-12">
        <div class=""> <!-- card shadow-sm -->
          <div class="h-auto"> <!-- card-body -->
            <div class="row">
              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 m-0 p-0">
                <PropertyList :loadingProperties="loadingProperties" :userProperties="properties"/>
              </div>
              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-8 p-0 m-0" id="adContainer">
                <!-- AdService Section -->
                <div class="position-relative h-100">
                  <div id="adPropertyContainer" class="ad-property-container no-scroll"> <!-- modal-tab -->
<!--                    &lt;!&ndash; Loading &ndash;&gt;-->
<!--                    <div class="fw-bold pt-5 pb-5 mt-2 mb-2" v-if="loadingAdDetails">Please choose property advertise</div>-->
<!--                    <Loader :loading="loadingAdDetails"></Loader>-->
                    <AdDetails v-if="!loadingAdDetails" :id="propertyId"></AdDetails>
                  </div>
                </div>
                <!-- End AdService Section -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PropertyList from "./PropertyList";
import AdDetails from "../AdDetails";
import Loader from "../Loader"
import axios from "axios";

export default {
  name:"DashboardPortfolio",
  components: {
    "PropertyList": PropertyList,
    "AdDetails": AdDetails,
    "Loader": Loader
  },
  data(){
    return {
      properties: null,
      propertyId: null,
      user:this.$store.state.auth.user,
      loadingAdDetails: true,
      loadingProperties: true
    }
  },
  created() {
    this.$root.$on('refreshUserProperties', (filters = null, id, paginate) => this.getUserProperties(filters, id, paginate));
    this.$root.$on('setPropertyIdDashboard', (id) => this.setPropertyIdDashboard(id));
  },
  beforeDestroy() {
    // Must destroy global trigger before we leave the page/component, otherwise it will layer on each other
    // and when we call it, it will trigger several times, breaking functionality
    this.$root.$off('refreshUserProperties');
    this.$root.$off('setPropertyIdDashboard');
  },
  async mounted() {
    if (this.$store.state.auth.user && this.$store.state.auth.user.id) {
      await this.getUserProperties(null, this.$store.state.auth.user.id, 10);
    }
  },
  methods: {
    getUserProperties(filters = null, id, paginate = null) {
      this.loadingProperties = true;
      axios.post('api/properties/user/' + id + '/' + paginate, filters).then(({data})=>{
        this.properties = data;
        this.propertyId = null;
      }).catch(({response})=>{
        if (response && response.data) {
          alert(response.data.message)
        }
      }).finally(()=> {
        this.processing = false;
        this.loadingProperties = false;
      })
    },
    setPropertyIdDashboard(id) {
      if (id) {
        this.propertyId = id;
        let screenWidth = window.innerWidth;
        if (screenWidth <= 991) {
          let adPreview = document.getElementById('adContainer');
          if (adPreview) {
            adPreview.scrollIntoView({behavior: 'smooth', block: 'start'});
          }
        }
      }
    }
  },
  watch: {
    "propertyId": {
      handler: function (val) {
        this.loadingAdDetails = false;
      }, deep: true, immediate: true
    }
  }
}
</script>

<style scoped lang="scss">

.dashboard-header {
  letter-spacing: 2px;
}

.ad-property-container {
  border: 1px solid rgba(0,0,0,.125);
  margin: 15px 0 5px 0;
  height: 82vh;
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  z-index: 11;
  top: 11.5vh;
  left: 32vw;
  //background-color: rgba(33, 150, 243, 50%);
  //background-color: rgba(255, 255, 255, 70%);
  overflow-x: hidden;
  transition: 0.5s;
  border-radius: 10px;
  background-clip: border-box;
}

.ad-property-container a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: black;
  display: block;
  transition: 0.3s;
}

.ad-property-container a:hover {
  color: #818080;
}

</style>