<template>
  <div class="container-list-properties">

    <div class="row">
      <div class="col-md-12 col-sm-12 dashboard-properties position-relative h-100">
          <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 property-portfolio-filters">
            <PropertySearchFilters></PropertySearchFilters>
          </div>
          <!-- No Results -->
          <div class="fw-bold p-5 m-2" v-if="!pagination && noData">No results to show <br/>Try to amend your search criteria please</div>
          <!-- Loading -->
          <Loader class="mt-5 mb-5 pt-5 pb-5" :loading="loading" :small="false"></Loader>
          <!-- Loop properties -->
          <div v-if="!loading && properties && properties.data" class="properties-container">
          <div v-for="(property, index) in properties.data"
               :key="index"
          >
            <div :class="activeDashPropDetailsThumbnail === property.id ? 'property-details p-2 active-property-details' : 'property-details p-2'"
                 :id="'prop-'+property.id"
                 v-on:click="openPropDetailsModal(property.id, index)"
            >

              <div class="row ps-3 m-0 text-start property-icons">
                <div class="col-1">
                  <ul class="list-unstyled">
                    <!-- Price -->
                    <li>
                      <span data-tooltip="tooltip">
                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                        <span class="tooltiptext">Price</span>
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="col-7">
                  <ul class="list-unstyled w-100">
                    <!-- Price -->
                    <li class="list-relative">
                      <div v-if="showPriceRange(property)" class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <span v-if="showHourPriceRange(property)" class="price-color"> {{ calculateHourPriceRange(property) }} </span>
                        <span v-if="showHourPriceRange(property)" class="fw-bold"> per hour </span> <br v-if="showHourPriceRange(property)"/>
                        <span v-if="showNightPriceRange(property)" class="price-color"> {{ calculateNightPriceRange(property) }} </span>
                        <span v-if="showNightPriceRange(property)" class="fw-bold"> per night </span>
                        <!-- This only for Let Long-Term Rooms -->
                        <span v-if="showWeekPriceRange(property)" class="price-color"> {{ calculateWeekPriceRange(property) }} </span>
                        <span v-if="showWeekPriceRange(property)" class="fw-bold"> per week </span>
                        <span v-if="showMonthPriceRange(property)" class="price-color"> {{ calculateMonthPriceRange(property) }} </span>
                        <span v-if="showMonthPriceRange(property)" class="fw-bold"> per month </span>
                      </div>
                      <div v-else class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <span class="price-color"> {{ convertNumberToCommas(property.price) }} </span>
                        <span v-if="property.is_rent || checkAdIsLetLongTermOrStudio(property)" class="fw-bold"> per {{ property.property_rent_type.type }} </span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-3 category-label-column">
                  <div v-if="property.is_sale" class="ms-1 category-label text-center" style="color: green; background-color: lightgreen;">
                    <span class="p-1">FOR SALE</span>
                  </div>
                  <div v-if="property.is_let" class="ms-1 category-label text-center" style="color: white; background-color: deepskyblue;">
                    <span class="p-1">TO LET</span>
                  </div>
                  <div v-if="property.is_buy" class="ms-1 category-label text-center" style="color: green; background-color: lightgreen;">
                    <span class="p-1">TO BUY</span>
                  </div>
                  <div v-if="property.is_rent" class="ms-1 category-label text-center" style="color: white; background-color: deepskyblue;">
                    <span class="p-1">TO RENT</span>
                  </div>
                </div>
                <div class="col-1">
                  <!-- Start Action Buttons -->
                  <div class="action-dropdown">
                    <button @click.stop="preventParentClick()" class="btn-action-dropdown" type="button" :id="'actionDropdown'+property.id" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                      <font-awesome-icon icon="ellipsis-h" style="color: #868e96;" />
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end list-unstyled action-buttons" :aria-labelledby="'actionDropdown'+property.id">
                      <li class="action-item">
                        <router-link :to="{ name: 'addeditad', params: { id: property.id }}">
                          <button :class="processing ? 'disabled action-button-edit' : 'action-button-edit'">
                            <font-awesome-icon icon="pencil-alt" class="min-width-30 icon-edit" />
                            EDIT
                          </button>
                        </router-link>
                      </li>
                      <li class="action-item">
                        <button :class="processing ? 'disabled action-button-publish' : 'action-button-publish'"
                                @click.stop="publishProp(property.id, property.is_published)"
                        >
                          <font-awesome-icon icon="paper-plane" class="min-width-30 icon-publish" />
                          {{ property.is_published ? "UNPUBLISH" : "PUBLISH" }}
                        </button>
                      </li>
                      <li class="action-item">
                        <button :class="processing ? 'disabled action-button-complete' : 'action-button-complete'"
                                @click.stop="completeProp(property.id, property.is_completed)"
                        >
                          <font-awesome-icon icon="check" class="min-width-30 icon-complete" />
                          {{ property.is_completed ? "UNCOMPLETE" : "COMPLETE" }}
                        </button>
                      </li>
                      <li class="action-item">
                        <button :class="processing ? 'disabled action-button-delete' : 'action-button-delete'"
                                @click.stop="deleteProp(property.id)">
                          <font-awesome-icon icon="trash" class="min-width-30 icon-delete" />
                          DELETE
                        </button>
                      </li>
                    </ul>
                    <!-- Confirmation Message -->
                    <div>
                      <p v-if="showSuccess && validationSuccess" class="success-info error-popup text-center">
                        <font-awesome-icon icon="thumbs-up" class="icon-fa" style="color: dodgerblue" /> {{ validationSuccess }}
                      </p>
                    </div>
                    <!-- End Confirmation Message -->
                    <div v-if="showError && validationErrors && validationErrors.error_id && validationErrors.error_id[0] === property.id" class="error-popup">
                      <p v-if="validationErrors && validationErrors.free_ad_exists" class="required-info text-center">
                        {{ validationErrors.free_ad_exists[0] }}
                      </p>
                      <p v-if="validationErrors && validationErrors.ad_is_unpaid" class="required-info text-center">
                        {{ validationErrors.ad_is_unpaid[0] }}
                      </p>
                      <p v-if="validationErrors && validationErrors.is_completed_error" class="required-info text-center">
                        {{ validationErrors.is_completed_error[0] }}
                      </p>
                      <p v-if="validationErrors && validationErrors.ad_is_completed" class="required-info text-center">
                        {{ validationErrors.ad_is_completed[0] }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
<!--              <div class="action-buttons">-->
<!--                <ul class="list-unstyled">-->
<!--                  <li class="d-inline-block action-item">-->
<!--                    <button :class="(property.is_completed ? (processing ? 'disabled action-button-complete' : 'action-button-complete') : (processing ? 'disabled action-button-complete completed' : 'action-button-complete completed'))"-->
<!--                            @click.stop="completeProp(property.id, property.is_completed)"-->
<!--                    >-->
<!--                      {{ property.is_completed ? "UNCOMPLETE" : "COMPLETE" }}-->
<!--                    </button>-->
<!--                  </li>-->
<!--                  <li class="d-inline-block action-item">-->
<!--                    <button :class="(property.is_published ? (processing ? 'disabled action-button-publish' : 'action-button-publish') : (processing ? 'disabled action-button-publish published' : 'action-button-publish published'))"-->
<!--                            @click.stop="publishProp(property.id, property.is_published)"-->
<!--                    >-->
<!--                      {{ property.is_published ? "UNPUBLISH" : "PUBLISH" }}-->
<!--                    </button>-->
<!--                  </li>-->
<!--                  <li class="d-inline-block action-item">-->
<!--                    <router-link :to="{ name: 'addeditad', params: { id: property.id }}">-->
<!--                      <button :class="processing ? 'disabled action-button-edit' : 'action-button-edit'">EDIT</button>-->
<!--                    </router-link>-->
<!--                  </li>-->
<!--                  <li class="d-inline-block action-item">-->
<!--                    <button :class="processing ? 'disabled action-button-delete' : 'action-button-delete'" @click.stop="deleteProp(property.id)">DELETE</button>-->
<!--                  </li>-->
<!--                </ul>-->
<!--                <div v-if="validationErrors && validationErrors.error_id && validationErrors.error_id[0] === property.id">-->
<!--                  <p v-if="validationErrors && validationErrors.free_ad_exists" class="required-info text-center">-->
<!--                    {{ validationErrors.free_ad_exists[0] }}-->
<!--                  </p>-->
<!--                  <p v-if="validationErrors && validationErrors.ad_is_unpaid" class="required-info text-center">-->
<!--                    {{ validationErrors.ad_is_unpaid[0] }}-->
<!--                  </p>-->
<!--                  <p v-if="validationErrors && validationErrors.is_completed_error" class="required-info text-center">-->
<!--                    {{ validationErrors.is_completed_error[0] }}-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
              <!-- End Action Buttons -->

              <div class="property-info-list-relative">
                <!-- property_category_id 7 = Room -->
                <!-- So we have
                property_type_details - such as Semi-detached etc
                property_type - such as Mansion, Warehouse, House etc
                property_category - such as Luxury, Room, Industrial, Commercial etc.
                -->
                <div v-if="property.property_category_id !== 7 && property.property_type && property.property_type_details" class="text-start ps-3 pb-1 property-info text-truncate-item no-text-wrap overflow-hidden text-truncate">
                <span v-if="property.property_category && property.property_category.type === 'Residential'" class='legend-residential'></span>
                <span v-if="property.property_category && property.property_category.type === 'Commercial'" class='legend-commercial'></span>
                <span v-if="property.property_category && property.property_category.type === 'Industrial'" class='legend-industrial'></span>
                <span v-if="property.property_category && property.property_category.type === 'Land'" class='legend-land'></span>
                <span v-if="property.property_category && property.property_category.type === 'Luxury'" class='legend-luxury'></span>
                <span v-if="property.property_category && property.property_category.type === 'Special Use'" class='legend-special-use'></span>
                <span v-if="property.property_category && property.property_category.type === 'Room'" class='legend-room'></span>

                <span class="fw-bold">
                  {{
                    property.property_type.type  +
                    ' ' +
                    property.property_type_details.type_detail
                  }}
                </span>
                  {{
                    ' - ' +
                    (property.address.county ? property.address.county : '') +
                    (property.address.city ? ', ' + property.address.city : '') +
                    (property.address.line_1 ? ', ' + property.address.line_1 : '') +
                    (property.address.postcode ? ', ' + property.address.postcode : '')
                  }}
                </div>
                <div v-else-if="property.property_category_id !== 7 && property.property_type && !property.property_type_details" class="text-start ps-3 pb-1 property-info text-truncate-item no-text-wrap overflow-hidden text-truncate">
                <span v-if="property.property_category && property.property_category.type === 'Residential'" class='legend-residential'></span>
                <span v-if="property.property_category && property.property_category.type === 'Commercial'" class='legend-commercial'></span>
                <span v-if="property.property_category && property.property_category.type === 'Industrial'" class='legend-industrial'></span>
                <span v-if="property.property_category && property.property_category.type === 'Land'" class='legend-land'></span>
                <span v-if="property.property_category && property.property_category.type === 'Luxury'" class='legend-luxury'></span>
                <span v-if="property.property_category && property.property_category.type === 'Special Use'" class='legend-special-use'></span>
                <span v-if="property.property_category && property.property_category.type === 'Room'" class='legend-room'></span>

                <span class="fw-bold">
                  {{
                    property.property_category.type +
                    ' ' +
                    property.property_type.type
                  }}
                </span>
                  {{
                    ' - ' +
                    (property.address.county ? property.address.county : '') +
                    (property.address.city ? ', ' + property.address.city : '') +
                    (property.address.line_1 ? ', ' + property.address.line_1 : '') +
                    (property.address.postcode ? ', ' + property.address.postcode : '')
                  }}
                </div>
                <div v-else class="text-start ps-3 pb-1 property-info text-truncate-item no-text-wrap overflow-hidden text-truncate">
                <span v-if="property.property_category && property.property_category.type === 'Residential'" class='legend-residential'></span>
                <span v-if="property.property_category && property.property_category.type === 'Commercial'" class='legend-commercial'></span>
                <span v-if="property.property_category && property.property_category.type === 'Industrial'" class='legend-industrial'></span>
                <span v-if="property.property_category && property.property_category.type === 'Land'" class='legend-land'></span>
                <span v-if="property.property_category && property.property_category.type === 'Luxury'" class='legend-luxury'></span>
                <span v-if="property.property_category && property.property_category.type === 'Special Use'" class='legend-special-use'></span>
                <span v-if="property.property_category && property.property_category.type === 'Room'" class='legend-room'></span>

                <span class="fw-bold">
                  {{
                    property.property_category.type
                  }}
                </span>
                  {{
                    ' - ' +
                    (property.address.county ? property.address.county : '') +
                    (property.address.city ? ', ' + property.address.city : '') +
                    (property.address.line_1 ? ', ' + property.address.line_1 : '') +
                    (property.address.postcode ? ', ' + property.address.postcode : '')
                  }}
                </div>
              </div>
              <!--            <div class="row property-info m-0 p-0 ps-3">-->
              <!--              <span class="fw-bold">Posted:</span>-->
              <!--              {{ calculateCreatedDate(property.created_at) }}-->
              <!--            </div>-->
              <!--            <div class="property-info m-0 pb-1 ps-3 text-start">-->
              <!--                <span class="fw-bold">Posted:</span>-->
              <!--                {{ calculateCreatedDate(property.created_at) }}-->
              <!--            </div>-->
              <div class="row property-info m-0 pt-1 pb-1 ps-3 text-start">
                <div class="col-6 m-0 p-0 text-start">
                  <span class="fw-bold">Posted:</span>
                  {{ calculateCreatedDate(property.created_at) }}
                </div>
                <div class="col-6 m-0 p-0 text-end position-relative">
                  <AddBookmark v-if="property.is_published" :id="property.id" :type="'Property'"></AddBookmark>
                </div>
              </div>
              <div v-if="property.is_let || property.is_rent" class="row property-info m-0 pt-1 pb-1 ps-3 text-start">
                <div class="col-12 m-0 p-0 text-start">
                  <font-awesome-icon icon="calendar-day" style="color: green;"/>
                  <span class="fw-bold">{{property.is_rent ? ' Required Dates:' : ' Availability:'}}</span>
                  {{ property.date_start ? (property.date_end ? (moment.utc(property.date_start).format('DD/MM/YYYY') + ' - ' + moment.utc(property.date_end).format('DD/MM/YYYY')) : (moment.utc(property.date_start).format('DD/MM/YYYY') + ' - ' + 'TBD')) : 'None' }}
                </div>
              </div>
              <div class="row property-thumbnails">
                <div class="col-8">
                  <div :id="'carouselCaptions-'+index" class="carousel slide" data-bs-ride="carousel">

                    <div class="carousel-inner">
                      <div v-if="property.is_cash" class="cash-caption d-none d-md-block">
                        <p>Cash Only</p>
                      </div>
                      <div v-if="property.is_auction" class="auction-caption d-none d-md-block">
                        <p>Auction</p>
                      </div>
                      <div v-if="property.is_coming_soon" class="coming-soon-caption d-none d-md-block">
                        <p>Coming Soon</p>
                      </div>
                      <div v-if="property.documents_property_images && property.documents_property_images.length === 0">
                        <img :src="require(`@/assets/no-image-found.png`)"
                             class="d-block w-100 img-fluid thumbnails-height"
                             alt="..."
                        >
                      </div>
                      <div v-for="(doc, num) in property.documents_property_images" :key="num" :class="num === 0 ? 'active carousel-item' : 'carousel-item'">
                        <img :src="`${$s3bucket}${doc.file_path}`" @error="setDefaultImg"
                             class="d-block w-100 img-fluid thumbnails-img"
                             alt="..."
                        >
<!--                        <img v-if="getImage(doc.file_name)" :src="require(`@/assets/${doc.file_name}`)" class="d-block w-100 img-fluid thumbnails-img" alt="...">-->
<!--                        <img v-else :src="require(`@/assets/no-image-found.png`)" class="d-block w-100 img-fluid thumbnails-img" alt="...">-->
                      </div>
                    </div>

                    <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselCaptions-'+index" data-bs-slide="prev" v-on:click.stop="preventParentClick()">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" :data-bs-target="'#carouselCaptions-'+index" data-bs-slide="next" v-on:click.stop="preventParentClick()">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>

                  </div>
                </div>
                <div class="col-4 p-0 m-0 property-details-section">
<!--                  <div class="row p-0 m-0 text-start property-icons">-->
<!--                    <div class="col-3">-->
<!--                      <ul class="list-unstyled">-->
<!--                        &lt;!&ndash; Price &ndash;&gt;-->
<!--                        <li>-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <font-awesome-icon icon="pound-sign" class="icon-fa" />-->
<!--                        <span class="tooltiptext">Price</span>-->
<!--                      </span>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </div>-->
<!--                    <div class="col-8">-->
<!--                      <ul class="list-unstyled w-100">-->
<!--                        &lt;!&ndash; Price &ndash;&gt;-->
<!--                        <li class="list-relative">-->
<!--                          <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                            <span class="price-color"> {{ convertNumberToCommas(property.price) }} </span>-->
<!--                            <span v-if="property.is_rent || property.is_let" class="fw-bold"> per {{ property.property_rent_type.type }} </span>-->
<!--                          </div>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div v-if="!property.is_rent && !property.is_let" class="row p-0 m-0 text-start property-icons">
                    <div class="col-3">
                      <ul class="list-unstyled">
                        <!-- Tenure -->
                        <li>
                        <span data-tooltip="tooltip">
                          <font-awesome-icon icon="file-signature" class="icon-fa" />
                          <span class="tooltiptext">Tenure</span>
                        </span>
                        </li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <ul class="list-unstyled w-100">
                        <!-- Tenure -->
                        <li class="list-relative">
                          <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                            {{ property.is_freehold ? 'Freehold' : 'Leasehold' }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="row m-0 p-0 property-icons custom-col-length text-start">
                    <div class="col-6 m-0 p-0">
                      <div class="row m-0 p-0 property-icons">
                        <div class="col-3">
                          <ul class="list-unstyled">
                            <!-- Suite/Apartment Bedrooms -->
                            <li v-if="calculateSuiteBeds(property.property_details)">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="building" class="icon-fa" />
                            <span class="tooltiptext">Suite/Apartment Beds</span>
                          </span>
                            </li>
                            <!-- Bedrooms -->
                            <li v-if="calculateBeds(property.property_details)">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="bed" class="icon-fa" />
                            <span class="tooltiptext">Bedrooms</span>
                          </span>
                            </li>
                            <!-- Living Rooms -->
                            <li v-if="property.property_details.living_room">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="couch" class="icon-fa" />
                            <span class="tooltiptext">Living Rooms</span>
                          </span>
                            </li>
                            <!-- Bathrooms -->
                            <li v-if="property.property_details.bath_room">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="bath" class="icon-fa" />
                            <span class="tooltiptext">Bathrooms</span>
                          </span>
                            </li>
                            <!-- Downstairs WC -->
                            <li v-if="property.property_details.downstairs_wc">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="toilet" class="icon-fa" />
                            <font-awesome-icon icon="hand-point-down" class="icon-fa" />
                            <span class="tooltiptext">Downstairs WC</span>
                          </span>
                            </li>
                            <!-- Garden -->
                            <li v-if="calculateGarden(property.property_details.front_garden, property.property_details.rear_garden)">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="seedling" />
                            <span class="tooltiptext">Garden</span>
                          </span>
                            </li>
                            <!-- Parking -->
                            <li v-if="calculateParking(property.property_details.driveway, property.property_details.garage, property.property_details.parking, property.property_details.parking_spaces)">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="parking" />
                            <span class="tooltiptext">Parking</span>
                          </span>
                            </li>
                          </ul>
                        </div>
                        <div class="col-3">
                          <ul class="list-unstyled">
                            <!-- Suite/Apartment Bedrooms -->
                            <li v-if="calculateSuiteBeds(property.property_details)">
                              {{ calculateSuiteBeds(property.property_details) }}
                            </li>
                            <!-- Bedrooms -->
                            <li v-if="calculateBeds(property.property_details)">
                              {{ calculateBeds(property.property_details) }}
                            </li>
                            <!-- Living Rooms -->
                            <li v-if="property.property_details.living_room">
                              {{ property.property_details.living_room }}
                            </li>
                            <!-- Bathrooms -->
                            <li v-if="property.property_details.bath_room">
                              {{ property.property_details.bath_room }}
                            </li>
                            <!-- Downstairs WC -->
                            <li v-if="property.property_details.downstairs_wc">
                              {{ property.property_details.downstairs_wc }}
                            </li>
                            <!-- Garden -->
                            <li v-if="calculateGarden(property.property_details.front_garden, property.property_details.rear_garden)">
                              {{ calculateGarden(property.property_details.front_garden, property.property_details.rear_garden) }}
                            </li>
                            <!-- Parking -->
                            <li v-if="calculateParking(property.property_details.driveway, property.property_details.garage, property.property_details.parking, property.property_details.parking_spaces)">
                              {{ calculateParking(property.property_details.driveway, property.property_details.garage, property.property_details.parking, property.property_details.parking_spaces) }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 m-0 p-0">
                      <div class="row m-0 p-0 property-icons">
                        <div class="col-3">
                          <ul class="list-unstyled">
                            <!-- EPC Rating -->
                            <li v-if="property.epc_rating">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="poll-h" class="icon-fa" />
                            <span class="tooltiptext">EPC Rating</span>
                          </span>
                            </li>
                            <!-- Accessibility -->
                            <li v-if="property.is_accessible">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="wheelchair" class="icon-fa" />
                            <span class="tooltiptext">Accessibility</span>
                          </span>
                            </li>
                            <!-- Shared Ownership -->
                            <li v-if="property.is_shared_ownership">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="handshake" class="icon-fa" />
                            <span class="tooltiptext">Shared Ownership</span>
                          </span>
                            </li>
                            <!-- Over 60's only -->
                            <li v-if="property.is_over_60s">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="hand-holding-heart" class="icon-fa" />
                            <span class="tooltiptext">Over 60's only</span>
                          </span>
                            </li>
                            <!-- HMO -->
                            <li v-if="property.is_hmo">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="users" class="icon-fa" />
                            <span class="tooltiptext">HMO</span>
                          </span>
                            </li>
                            <!-- Furnished -->
                            <li v-if="property.is_furnished">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="chair" class="icon-fa" />
                            <span class="tooltiptext">Furnished</span>
                          </span>
                            </li>
                            <!-- Tenanted -->
                            <li v-if="property.is_tenanted">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="house-user" class="icon-fa" />
                            <span class="tooltiptext">Tenanted</span>
                          </span>
                            </li>
                          </ul>
                        </div>
                        <div class="col-3">
                          <ul class="list-unstyled">
                            <!-- EPC Rating -->
                            <li v-if="property.epc_rating  && (!property.is_rent && !property.is_buy)">
                              {{ property.epc_rating }}
                            </li>
                            <!-- Accessibility -->
                            <li v-if="property.is_accessible">
                              Yes
                            </li>
                            <!-- Shared Ownership -->
                            <li v-if="property.is_shared_ownership">
                              Yes
                            </li>
                            <!-- Over 60's only -->
                            <li v-if="property.is_over_60s">
                              Yes
                            </li>
                            <!-- HMO -->
                            <li v-if="property.is_hmo">
                              Yes
                            </li>
                            <!-- Furnished -->
                            <li v-if="property.is_furnished">
                              Yes
                            </li>
                            <!-- Talented -->
                            <li v-if="property.is_tenanted">
                              Yes
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

           </div>
            <!-- Start AdSense -->
            <div v-if="(index !== 0 && index % 4 === 0) || checkIfLastIndex(index)">
              <AdSense></AdSense>
            </div>
            <!-- End AdSense -->

          </div>
          </div>
          <!-- End Loop properties -->

          <!-- Pagination -->
          <ul class="pagination justify-content-center" v-if="pagination && !loading">
            <li class="page-item" :class="{disabled: metaData.current_page === 1}">
              <a class="page-link" @click="previous"><span aria-hidden="true">&laquo;</span></a>
            </li>
            <li
                v-for="(number, index) in paginationData"
                class="page-item"
                :class="{active: metaData.current_page === number}"
                :key="number"
            >
                <a class="page-link" @click="navigateTo(null, number)">{{ number }}</a>
            </li>
            <li class="page-item" :class="{disabled: metaData.current_page === metaData.last_page}">
              <a class="page-link" @click="next"><span aria-hidden="true">&raquo;</span></a>
            </li>
          </ul>

          <!-- End Prop Details Modal -->

      </div>

<!--      <div class="col-md-6 col-sm-12">-->
<!--        <Map/>-->
<!--      </div>-->
    </div>

  </div>
</template>

<script>
import axios from "axios"
import AdDetails from '../AdDetails'
import Loader from "../Loader.vue"
import AddBookmark from "../AddBookmark"
import AdSense from "../AdSense"
import PropertySearchFilters from "./PropertySearchFilters"

export default {
  name: 'PropertyList',
  components: {
    'AdDetails': AdDetails,
    'Loader': Loader,
    'AddBookmark': AddBookmark,
    'AdSense': AdSense,
    'PropertySearchFilters': PropertySearchFilters
  },
  props: ['loadingProperties', 'userProperties'],
  data(){
    return {
      defaultImg: require('@/assets/no-image-found.png'),
      activePropertyDetailsModal: false,
      activeDashPropDetailsThumbnail: undefined,
      properties: this.userProperties,
      propDetailsId: null,
      pagination: false,
      metaData: null,
      linksData: null,
      paginationData: null,
      loading: this.loadingProperties,
      noData: false,
      processing: true,
      validationErrors: null,
      showError: false,
      validationSuccess: null,
      showSuccess: false
    }
  },
  mounted: function() {
  },
  methods: {
    // Helpers for LET ads to reflect short-term and long-term lets data
    checkAdIsLetShortTermOrRoom(prop) {
      if (prop && prop.is_let && ((prop.is_short_term && !prop.property_details.studio_room) || prop.is_room)) {
        return true;
      } else {
        return false;
      }
    },
    checkAdIsLetLongTermOrStudio(prop) {
      if (prop && prop.is_let && ((!prop.is_short_term && !prop.is_room) || prop.property_details.studio_room)) {
        return true;
      } else {
        return false;
      }
    },
    showPriceRange(prop) {
      if (this.checkAdIsLetShortTermOrRoom(prop)) {
        return true;
      } else {
        return false;
      }
    },
    showHourPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get hourly prices
        let hourlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 2) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (hourlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateHourPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxHourlyPrice = null;
        let minHourlyPrice = null;

        // get hourly prices
        let hourlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 2) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (hourlyPrices.length > 0) {
          maxHourlyPrice = Math.max.apply(null, hourlyPrices);
          minHourlyPrice = Math.min.apply(null, hourlyPrices);
        }

        if (minHourlyPrice && maxHourlyPrice) {
          if (minHourlyPrice == maxHourlyPrice) {
            return this.convertNumberToCommas(minHourlyPrice);
          } else {
            return this.convertNumberToCommas(minHourlyPrice) + ' - ' + this.convertNumberToCommas(maxHourlyPrice);
          }
        }
      }
    },
    showNightPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get nightly prices
        let nightlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 3) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (nightlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateNightPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxNightlyPrice = null;
        let minNightlyPrice = null;

        // get nightly prices
        let nightlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 3) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (nightlyPrices.length > 0) {
          maxNightlyPrice = Math.max.apply(null, nightlyPrices);
          minNightlyPrice = Math.min.apply(null, nightlyPrices);
        }

        if (minNightlyPrice && maxNightlyPrice) {
          if (minNightlyPrice == maxNightlyPrice) {
            return this.convertNumberToCommas(minNightlyPrice);
          } else {
            return this.convertNumberToCommas(minNightlyPrice) + ' - ' + this.convertNumberToCommas(maxNightlyPrice);
          }
        }
      }
    },
    showWeekPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get weekly prices
        let weeklyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 4) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (weeklyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateWeekPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxWeeklyPrice = null;
        let minWeeklyPrice = null;

        // get weekly prices
        let weeklyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 4) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (weeklyPrices.length > 0) {
          maxWeeklyPrice = Math.max.apply(null, weeklyPrices);
          minWeeklyPrice = Math.min.apply(null, weeklyPrices);
        }

        if (minWeeklyPrice && maxWeeklyPrice) {
          if (minWeeklyPrice == maxWeeklyPrice) {
            return this.convertNumberToCommas(minWeeklyPrice);
          } else {
            return this.convertNumberToCommas(minWeeklyPrice) + ' - ' + this.convertNumberToCommas(maxWeeklyPrice);
          }
        }
      }
    },
    showMonthPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get monthly prices
        let monthlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 5) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (monthlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateMonthPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxMonthlyPrice = null;
        let minMonthlyPrice = null;

        // get monthly prices
        let monthlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 5) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (monthlyPrices.length > 0) {
          maxMonthlyPrice = Math.max.apply(null, monthlyPrices);
          minMonthlyPrice = Math.min.apply(null, monthlyPrices);
        }

        if (minMonthlyPrice && maxMonthlyPrice) {
          if (minMonthlyPrice == maxMonthlyPrice) {
            return this.convertNumberToCommas(minMonthlyPrice);
          } else {
            return this.convertNumberToCommas(minMonthlyPrice) + ' - ' + this.convertNumberToCommas(maxMonthlyPrice);
          }
        }
      }
    },
    // End Helpers for LET ads to reflect short-term and long-term lets data
    navigateTo(url, page = null) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.loading = true;
      if (page) {
        url = this.linksData.first.substring(0, this.linksData.first.lastIndexOf('=') + 1) + page;
      }
      axios.post(url).then(({data})=>{
        this.properties = data;
        this.calculatePagination();
      }).catch(({response})=>{
        alert(response.data.message)
      }).finally(()=> {
        this.processing = false;
        this.loading = false;
      })
    },
    previous() {
      if (this.linksData.prev) {
        this.navigateTo(this.linksData.prev)
      }
    },
    next() {
      if (this.linksData.next) {
        this.navigateTo(this.linksData.next)
      }
    },
    getImage(file_name) {
      try {
        return require(`@/assets/${file_name}`);
      } catch (e) {
        return false
      }
    },
    setDefaultImg(e) {
      e.target.src = require(`@/assets/no-image-found.png`);
    },
    async completeProp(id, completed) {
      let isCompleted = completed ? 'open (uncomplete)' : 'close (complete)';
      if (!confirm("Are you sure you want to " + isCompleted + " this advertise?")) {
        return;
      }
      this.processing = true;

      await axios.post("api/properties/" + id + "/complete").then(response => {
        this.validationSuccess = response.data.message;
        this.showSuccess = true;
        const timeout = setTimeout(() => {
          this.showSuccess = false;
          this.validationSuccess = null;
          clearTimeout(timeout);
        }, 4000);
        this.validationErrors = null;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.showError = true;
          const timeout = setTimeout(() => {
            this.showError = false;
            this.validationErrors = null;
            clearTimeout(timeout);
          }, 7000);
          this.processing = false
          // this.validationMessage = data.message;
        }
      }).finally(() => {
        this.processing = false

        // Refresh user data so we get new favourites in the listing
        if (this.validationErrors === null) {
          this.$root.$emit('refreshUserProperties', null, this.$store.state.auth.user.id, 15);
        }
      })
    },
    async deleteProp(id) {
      if (!confirm("Are you sure you want to remove this property form your portfolio? ID: " + id)) {
        return;
      }
      this.processing = true;

      await axios.post("api/properties/" + id + "/delete").then(response => {
        this.validationSuccess = response.data.message;
        this.showSuccess = true;
        const timeout = setTimeout(() => {
          this.showSuccess = false;
          this.validationSuccess = null;
          clearTimeout(timeout);
        }, 4000);
        this.validationErrors = null;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.showError = true;
          const timeout = setTimeout(() => {
            this.showError = false;
            this.validationErrors = null;
            clearTimeout(timeout);
          }, 7000);
          this.processing = false
          // this.validationMessage = data.message;
        }
      }).finally(() => {
        this.processing = false

        // Refresh user data so we get new favourites in the listing
        if (this.validationErrors === null) {
          this.$root.$emit('refreshUserProperties', null, this.$store.state.auth.user.id, 15);
        }
      })
    },
    async publishProp(id, published) {
      let isPublished = published ? 'unpublish' : 'publish';
      if (!confirm("Are you sure you want to " + isPublished + " this advertise?")) {
        return;
      }
      this.processing = true;

      await axios.post("api/properties/" + id + "/publish").then(response => {
        this.validationSuccess = response.data.message;
        this.showSuccess = true;
        const timeout = setTimeout(() => {
          this.showSuccess = false;
          this.validationSuccess = null;
          clearTimeout(timeout);
        }, 4000);
        this.validationErrors = null;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.showError = true;
          const timeout = setTimeout(() => {
            this.showError = false;
            this.validationErrors = null;
            clearTimeout(timeout);
          }, 7000);
          this.processing = false
          // this.validationMessage = data.message;
        }
      }).finally(() => {
        this.processing = false

        // Refresh user data so we get new favourites in the listing
        if (this.validationErrors === null) {
          this.$root.$emit('refreshUserProperties', null, this.$store.state.auth.user.id, 15);
        }
      })
    },
    openPropDetailsModal(id, index) {
      // this.$root.$emit('openPropDetailsGlobal', id, index);
      this.setActiveDashPropDetailsThumbnail(id);
      this.$root.$emit('setPropertyIdDashboard', id);
    },
    setActiveDashPropDetailsThumbnail(id) {
      this.activeDashPropDetailsThumbnail = id;
    },
    preventParentClick() {
      // Do nothing, its just used to prevent parent function trigger (setActivePropertyDetailsThumbnail)
    },
    checkIfLastIndex(index){
      return index === this.properties.data.length - 1;
    },
    calculateBeds(propDetails) {
      return Number(propDetails.single_room) + Number(propDetails.double_room) + Number(propDetails.twin_room) + Number(propDetails.twin_double_room) +
          Number(propDetails.triple_room) + Number(propDetails.quadruple_room) + Number(propDetails.family_room) + Number(propDetails.studio_room);
    },
    calculateSuiteBeds(propDetails) {
      return Number(propDetails.single_room_suite) + Number(propDetails.double_room_suite);
    },
    calculateParking(driveway = 0, garage = 0, parking = 0, parkingSpaces = 0) {
      if (parking && parkingSpaces > 0) {
        return Number(driveway) + Number(garage) + Number(parkingSpaces);
      } else {
        return Number(driveway) + Number(garage) + Number(parking);
      }
    },
    calculateGarden(frontGarden, rearGarden) {
      return frontGarden + rearGarden;
    },
    calculateCreatedDate(date) {
      let resultDate = null;

      let currentDate = new Date();
      let propDate = new Date(date);
      let numberOfDays = (currentDate.getTime() - propDate.getTime()) / (1000*60*60*24);

      switch (true) {
        case numberOfDays <= 1:
          resultDate = "Today";
          break;
        case (numberOfDays <= 30 && numberOfDays > 1):
          if(numberOfDays < 2) {
            resultDate = Math.round(numberOfDays)+" day ago";
          } else {
            resultDate = Math.round(numberOfDays)+" days ago";
          }
          break;
        case numberOfDays > 30:
          let numberOfMonths = Math.round(numberOfDays / 30);
          if(numberOfMonths === 1) {
            resultDate = numberOfMonths+" month ago";
          } else {
            resultDate = numberOfMonths+" months ago"
          }
          break;
      }

      return resultDate;

    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    calculatePagination() {
      if (this.metaData && this.linksData) {

        // Current page any but total pages less than 10
        if (this.metaData.last_page < 10) {
          this.paginationData = this.metaData.last_page
        }

        // Current page 2 or less and last_page pages more than 10
        if (this.metaData.current_page <= 2 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // Current page 3 and last_page pages more than 10
        if (this.metaData.current_page === 3 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, 4, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // Current page 4 and last_page pages more than 10
        if (this.metaData.current_page === 4 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, 4, 5, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page 5 and last_page pages more than 10
        if (this.metaData.current_page === 5 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, this.metaData.current_page - 1, this.metaData.current_page, this.metaData.current_page + 1, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page 6 or more and last_page pages more than 10
        if (this.metaData.current_page >=6 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, '...', this.metaData.current_page - 1, this.metaData.current_page, this.metaData.current_page + 1, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page almost or equal last_page which is more than 10
        if (this.metaData.last_page >= 10 &&
            (this.metaData.current_page === this.metaData.last_page ||
                this.metaData.current_page === this.metaData.last_page - 1 ||
                this.metaData.current_page === this.metaData.last_page - 2
            )
        ) {
          this.paginationData = [1, 2, 3, '...', this.metaData.last_page - 3, this.metaData.last_page - 2, this.metaData.last_page - 1, this.metaData.last_page]
        }
      }
    }
  },
  created: function () {

  },
  watch: {
    'userProperties': {
      handler: function () {
        this.properties = this.userProperties;
        this.metaData = this.userProperties.meta;
        this.linksData = this.userProperties.links;
      }, deep: true
    },
    'properties': {
      handler: function () {
        this.metaData = this.properties.meta;
        this.linksData = this.properties.links;
        if (
            this.properties &&
            this.properties.data &&
            this.properties.data.length > 0
        ) {
          this.loading = false;
          this.noData = false;
          this.calculatePagination();
          this.pagination = true;
        } else {
          this.loading = false;
          this.noData = true;
          this.pagination = false;
        }
      }, deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.category-label-column:hover {
  z-index: 10000;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}
.category-label {
  border: 1px solid #868e96;
  border-radius: 10px;
  width: 100px;
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  &:hover {
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    width: fit-content;
    overflow: visible;
  }
}

/* Error Message */
.error-popup {
  position: fixed;
  max-width: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 5px;
  z-index: 99999;
}

/* Property Portfolio Search Filters */
.property-portfolio-filters {
  align-self: flex-start;
  position: -webkit-sticky;
  position: sticky;
  height: 11vh;
  z-index: 1002;
  padding-top: 5px;
  margin: 5px 0 5px 0;
  top: 10.5vh;
}
/* End Property Portfolio Search Filters */

/* Start Action Buttons */
.dashboard-properties .action-dropdown {
  z-index: 1001;
  min-height: 30px;
  text-align: end;
  background-color: rgba(255, 255, 255 , 0);
  color: #868e96;
  cursor: pointer;

  .btn-action-dropdown {
    border: none;
    border-radius: 50%;
    background-color: #e9ecef;
    cursor: pointer;
  }
  .btn-action-dropdown:hover {
    cursor: pointer;
  }
}

.action-dropdown:hover {
  cursor: pointer;
}

.action-buttons {
  z-index: 1001;
  .action-item {
    padding: 2px 5px 2px 5px;
    margin: 2px 5px 2px 5px;
    min-width: 80px;
    font-size: 13px;
  }

  .action-button-complete {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-complete:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-complete:hover .icon-complete {
    color: #42b983;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-publish {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-publish:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-publish:hover .icon-publish {
    color: deepskyblue;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-edit {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-edit:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-edit:hover .icon-edit {
    color: orange;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-delete {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-delete:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-delete:hover .icon-delete {
    color: red;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }
}
/* End Action Buttons */

a.ad-details-closebtn {
  position: fixed;
  padding: 0 !important;
  width: 28px;
  height: 28px;
  top: 50%;
  left: 30.4%;
  font-size: 24px !important;
  z-index: 9999;
  visibility: hidden;
  transition: 0.7s;
  -webkit-transition-duration: 0.7s;
  color: #198754;
  border-radius: 50% 0 0 50%;
  //border: 2px solid rgba(0, 0, 0, .125);
  border-right: none !important;
  border-left: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  line-height: 0;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);

  :hover {
    color: red;
    transition: 0.7s;
    -webkit-transition-duration: 0.7s;
  }
}

a.ad-details-closebtn .tooltiptext:hover {
  color: white !important;
}

a.ad-details-closebtn #adDetailsChevronCloseBtn {
  position: absolute;
  top: 5%;
  left: 10%;
}

/* Double Chevron Animation Once */
.pulse-animation-once {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s;
  animation-iteration-count: 2;
}
/* End Double Chevron Animation Once */

/* Start Text truncate - white space - for long texts and icons */
.property-info-list-relative {
  width: 100%;
  min-height: 25px;
  position: relative;
}

.list-relative {
  width: 100%;
  position: relative;
}

.text-truncate-item {
  width: 100%;
  box-sizing: border-box;
  transition: 0.7s;
  border-radius: 5px;
}

.text-truncate-item:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing: border-box;
  overflow: visible !important;
  white-space: normal !important;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0 0 0 1px;
  z-index: 1000;
}
/* End Text truncate - white space - for long texts and icons */

.dashboard-properties {
  position: relative;
  z-index: 10;
  //min-height: 81vh;
  background-color: rgba(255, 255, 255, 85%);
  padding-bottom: 8%;
  .property-details {
    background-color: #e9ecef;
    border-radius: 15px;
    transition: 0.5s;
    margin: 5px 0px 5px 0px;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  }
  .active-property-details {
    background-color: white;
    transition: 0.5s;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  }
  /* change transition duration to control the speed of fade effect */
  .carousel-item {
    transition: transform 1s ease-in-out;
  }

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: opacity 0s 1s;
  }
}

.container-list-properties {
  width: 100%;
  height: auto;
  margin: auto;
  .row {
    margin: auto;
  }
  .col-6 {
    padding: 0;
  }
  .col-md-6 {
    padding: 0;
  }
}

/* Property Details Modal */

/* Fade effect for transition elements */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modal-tab::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modal-tab {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Ad Details Modal */
.propDetailsModal {
  height: 85vh;
  opacity: 0;
  visibility: hidden;
  width: 67vw;
  position: fixed;
  z-index: 1;
  top: 10.5vh;
  left: 32vw;
  //background-color: rgba(33, 150, 243, 50%);
  background-color: rgba(255, 255, 255, 70%);
  overflow-x: hidden;
  transition: 0.5s;
  border-radius: 10px;
  background-clip: border-box;
}

.propDetailsModal a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: black;
  display: block;
  transition: 0.3s;
}

.propDetailsModal a:hover {
  color: #818080;
}

//.propDetailsModal .closebtn {
//  position: absolute;
//  top: 50%;
//  left: 0;
//  font-size: 36px;
//  margin-left: 50px;
//}

.propDetailsModalStickyTop {
  width: 100%;
  height: 30px;
  transition: 0.5s;
  background-color: rgba(255, 255, 255, 70%);
}
.propDetailsModalStickyTop:hover {
  transition: 0.5s;
  background-color: rgba(255, 255, 255, 100%);
}

/* End of Ad Details Modal */

.property-info {
  cursor: pointer;
  font-size: 13px;
  //font-weight: bold;
}

.property-icons {
  --bs-gutter-x: 0;
  ul {
    margin: 0;
  }
}

.custom-col-length {
  .col-3 {
    width: 50% !important;
  }
}

/*
  START
  change some css so that images are always sized same
  use media queries to adjust that for mobile and other devices
*/

.property-thumbnails {
  .thumbnails-img {
    width: 100%;
    cursor: pointer;
    height: auto;
    max-height: 200px;
    min-height: auto;
    //max-height: 200px !important;
    //min-height: 200px !important;
  }
  .carousel-inner{
    width:100%;
    border-radius: 20px;
    .auction-caption {
      background-color: white;
      text-align: left;
      min-width: 80px;
      max-width: 80px;
      min-height: 25px;
      max-height: 25px;
      border-radius: 5% 5% 100% 5%;
      padding: 5px 5px 5px 10px;
      font-size: 11px;
      position: absolute;
      top: -1px;
      bottom: auto;
      left: -1px;
      z-index: 10;
    }
    .cash-caption {
      background-color: white;
      text-align: right;
      min-width: 80px;
      max-width: 80px;
      min-height: 25px;
      max-height: 25px;
      border-radius: 5% 5% 5% 100%;
      padding: 5px 3px 5px 5px;
      font-size: 11px;
      position: absolute;
      top: -1px;
      bottom: auto;
      right: 0;
      z-index: 10;
    }
    .coming-soon-caption {
      background-color: gold;
      color: black;
      text-align: center;
      min-width: 100%;
      max-width: 100%;
      min-height: 25px;
      max-height: 25px;
      border-radius: 100% 100% 3% 3%;
      padding: 5px 5px 5px 10px;
      font-size: 11px;
      position: absolute;
      //top: -1px;
      bottom: 0;
      left: -1px;
      z-index: 10;
    }
  }
  .property-details-section {
    .col-8 {
      width: 75% !important;
    }
  }
  .property-details-section *:hover {
    cursor: pointer !important;
  }
}
/*
  END
  change some css so that images are always sized same
  use media queries to adjust that for mobile and other devices
*/

/* start tooltip - popups info on icons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
}
/* end tooltip - popups info on icons */

.pagination {
  cursor: pointer;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .propDetailsModal a {
    font-size: 18px;
  }
}
@media screen and (max-width: 500px) {
  .category-label {
    font-size: 10px;
  }
  .property-portfolio-filters {
    height: 8vh !important;
  }
  .properties-container {
    margin-top: 5%;
    height: 65vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  a.ad-details-closebtn {
    left: 1%;
  }
  /* Ad Details Modal */
  .propDetailsModal {
    width: 90vw;
    height: 80vh;
    top: 15vh !important;
    left: 5vw;
    z-index: 10;
  }
  .property-thumbnails {
    .thumbnails-img {
      min-height: 200px !important;
    }
  }
}
// 768px
@media screen and (min-width: 501px) {
  .category-label {
    font-size: 10px;
  }
  .property-portfolio-filters {
    height: 7vh !important;
  }
  .properties-container {
    margin-top: 5%;
    height: 65vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  a.ad-details-closebtn {
    left: 2.3%;
  }
  /* Ad Details Modal */
  .propDetailsModal {
    width: 90vw;
    height: 80vh;
    top: 15vh !important;
    left: 5vw;
    z-index: 10;
  }
  .property-thumbnails {
    .thumbnails-img {
      min-height: 300px !important;
    }
  }
}
@media screen and (min-width: 769px) {
  .category-label {
    font-size: 16px;
  }
  .property-portfolio-filters {
    height: 5vh !important;
  }
  .properties-container {
    margin-top: 5%;
    height: 65vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  a.ad-details-closebtn {
    left: 2.3%;
  }
  /* Ad Details Modal */
  .propDetailsModal {
    width: 90vw;
    height: 80vh;
    top: 15vh !important;
    left: 5vw;
    z-index: 10;
  }
  .property-thumbnails {
    .thumbnails-img {
      min-height: 400px !important;
    }
  }
}
@media screen and (min-width: 992px) {
  .property-portfolio-filters {
    height: 9vh !important;
  }
  .properties-container {
    height: inherit;
    overflow-x: unset;
    overflow-y: unset;
  }
  a.ad-details-closebtn {
    left: 39%;
  }
  /* Ad Details Modal */
  .propDetailsModal {
    width: 58vw;
    height: 84vh;
    top: 10.5vh !important;
    left: 41vw;
  }
  .property-thumbnails {
    .thumbnails-img {
      min-height: 200px !important;
    }
  }
}
@media screen and (min-width: 1200px) {
  .property-portfolio-filters {
    height: 11vh !important;
  }
  a.ad-details-closebtn {
    left: 30.4%;
  }
  /* Ad Details Modal */
  .propDetailsModal {
    width: 67vw;
    height: 84vh;
    top: 10.5vh !important;
    left: 32vw;
  }
  .property-thumbnails {
    .thumbnails-img {
      min-height: 200px !important;
    }
  }
}
@media screen and (min-width: 1600px) {
  a.ad-details-closebtn {
    left: 30.6%;
  }
}
@media screen and (min-width: 1920px) {
  a.ad-details-closebtn {
    left: 31%;
  }
  /* Ad Details Modal */
  .propDetailsModal {
    width: 67vw;
    height: 84vh;
    top: 10.5vh !important;
    left: 32vw;
  }
  .property-thumbnails {
    .thumbnails-img {
      min-height: 300px !important;
    }
  }
}

</style>
